<template>
  <!-- <div class="columns no-margin"> -->
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">{{ headerText }} STASE</p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <template v-if="!isCreate">
            <b-field label="Angkatan:" custom-class="is-small">
              <p>{{ stase.angkatan }}</p>
            </b-field>
            <b-field label="Departemen:" custom-class="is-small">
              <p v-if="isLoaded">{{ stase.sub_departemen.nama }}</p>
            </b-field>
          </template>
          <template v-else>
            <b-field
              label="Angkatan"
              custom-class="is-small"
              :type="{'is-danger': errorMap.angkatan}"
              :message="errorMap.angkatan"
            >
              <generic-select
                v-model="stase.angkatan"
                apiPath="/programstudi/angkatan/options/"
                @input="validateInput('angkatan')"
              ></generic-select>
            </b-field>
            <b-field
              label="Departemen"
              custom-class="is-small"
              :type="{'is-danger': errorMap.sub_departemen}"
              :message="errorMap.sub_departemen"
            >
              <generic-select
                v-model="stase.sub_departemen"
                apiPath="/programstudi/subdepartemen/"
                @input="validateInput('sub_departemen')"
              ></generic-select>
            </b-field>
          </template>
          <b-field
            label="Koordinator"
            custom-class="is-small"
            :type="{'is-danger': errorMap.preceptor}"
            :message="errorMap.preceptor"
          >
            <generic-autocomplete
                apiPath="/anggota/preceptor/"
                v-model="stase.preceptor"
                @input="validateInput('preceptor')"
              ></generic-autocomplete>
          </b-field>

          <b-field
            label="Mulai"
            class="tgl-field"
            custom-class="is-small"
            :type="{'is-danger': errorMap.mulai}"
            :message="errorMap.mulai"
          >
            <date-picker v-model="stase.mulai" @input="validateInput('mulai')"></date-picker>
          </b-field>

          <b-field
            label="Hingga"
            class="tgl-field"
            custom-class="is-small"
            :type="{'is-danger': errorMap.hingga}"
            :message="errorMap.hingga"
          >
            <date-picker v-model="stase.hingga" @input="validateInput('hingga')"></date-picker>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Stase from "../models/staseCU.js";

export default {
  name: "StaseCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue")
  },
  data() {
    this.staseMdl = new Stase();
    this.objectMdl = this.staseMdl; // alias yg digunakan di cuMixin
    let obv = this.staseMdl.getObservables();
    return obv;
  },
  computed: {
    sub_departemen() {
      return this.stase.sub_departemen;
    }
  },
  methods: {
    onSaved(respObj) {
      if (this.isCreate) {
        this.$router.replace({
          name: "stase-detail",
          params: { id: respObj.id }
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
  watch: {
    stase: {
      // stase berubah, update edited
      handler(newValue, oldValue) {
        if (!this.staseMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>
