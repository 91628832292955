import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "stase";
const modelPath = `/${modelName}/`;

class Stase extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        sub_departemen: null,
        mulai: null,
        hingga: null,
        preceptor: null,
        stasepspd_set: []
    };
    let requiredFields = ["angkatan", "sub_departemen", "mulai", "hingga", "preceptor"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    if (tdata.preceptor) tdata.preceptor = tdata.preceptor.id;
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    delete data["stasepspd_set"];
    return data;
  }
}

export default Stase;